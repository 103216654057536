import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nfts"
    }}>{`NFTs`}</h1>
    <hr></hr>
    <p>{`Every character and farmland is a non-fungible token or NFT. If you own this token, it means you are the sole owner of this asset.`}</p>
    <p>{`Country Life : Farming Season uses ERC-721 to create non-fungible tokens. Players can manage their own NFTs across Polygon Chain.`}</p>
    <p><img alt="polygon" src={require("./public/images/polygon.png")} /></p>
    <hr></hr>
    <h2 {...{
      "id": "character"
    }}>{`Character`}</h2>
    <p>{`You will get characters from gacha boxes on the dashboard or buy them from the marketplace.`}</p>
    <p><img alt="coin" src={require("./public/images/char.png")} /></p>
    <ul>
      <li parentName="ul">{`Character drop rate from gacha box`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rarity`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Drop Rate`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Super Rare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Legend`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mythic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2%`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Characters with high rarity will get higher quest reward than characters with low rarity.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Character Skills`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Expert Farmer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Decrease duration when the character is working on the farm.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The Explorer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Decrease exploring duration.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Animal Lover`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Have a chance to get special products from animals.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Lucky Gatherer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Have a chance to get special products from plants.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Regeneration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Decrease quest cooldown duration.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Merchant Master`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Have a chance to get maximum CLC from quest reward.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2 {...{
      "id": "farmland"
    }}>{`Farmland`}</h2>
    <p>{`You can buy farmlands on the dashboard only.`}</p>
    <ul>
      <li parentName="ul">{`Standard Farm`}</li>
      <li parentName="ul">{`Great Farm`}</li>
    </ul>
    <p><img alt="coin" src={require("./public/images/howto-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      